<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <CCol>
            <strong>{{ $lang.opportunity_resource.crud.view }}</strong>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span @click="selected = 'OpportunityResourceDetail'">
              <CTab>
                <template slot="title">
                  {{ $lang.opportunity_resource.crud.view }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'OpportunityResourceMedia'">
              <CTab>
                <template slot="title">
                  {{ $lang.opportunity_resource.crud.view_media }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component :is="selected" @changeSelected="getTab($event)" style="margin-top: 20px"></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";
import OpportunityResourceDetail from "./OpportunityResourceDetail";
import OpportunityResourceMedia from "./OpportunityResourceMedia";

export default {
  name: "OpportunityResourceView",
  mixins: [Mixin],
  components: {
    OpportunityResourceDetail,
    OpportunityResourceMedia
  },
  data() {
    return {
      userId: "",
      tabs: ["OpportunityResourceDetail", "OpportunityResourceMedia"],
      selected: "OpportunityResourceDetail",
      currentId: "",
    };
  },
  mounted() {
    let self = this;
    self.currentId = this.$route.params.id;
  },
  methods: {
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
  },
};
</script>
