<template>
  <CRow>
    <CCol col="12" lg="12">
      <div class="detail-div">
        <CRow>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity_resource.form.title }}</p>
            <p class="custome-detail">{{ data.title }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity_resource.form.description }}</p>
            <p class="custome-detail">{{ data.description }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity_resource.form.noOfResource }}</p>
            <p class="custome-detail">{{ data.noOfResource }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity_resource.form.minBudget }}</p>
            <p class="custome-detail">{{ data.minBudget }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity_resource.form.maxBudget }}</p>
            <p class="custome-detail">{{ data.maxBudget }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity_resource.form.currency }}</p>
            <p class="custome-detail">{{ currency }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity_resource.form.user }}</p>
            <p class="custome-detail">{{ user }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity_resource.form.company }}</p>
            <p class="custome-detail">{{ companyName }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity_resource.form.experience }}</p>
            <p class="custome-detail">{{ experience }}</p>
          </CCol>
        </CRow>
      </div>
    </CCol>
  </CRow>
</template>
<script>

import store from "/src/store/store.js";
import { opportunity } from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "OpportunityResourceDetail",
  mixins: [Mixin],
  data() {
    return {
      submitType: "",
      userId: "",
      largeModal: false,
      statusId: "",
      statusLabel: "Block",
      statusColor: "danger",
      submitted: false,
      showTax: true,
      err_msg: "",
      dismissCountDown: 0,
      self: this,
      module: opportunity,
      data: [],
      currency: "",
      user: "",
      companyName: "",
      experience: "",
    };
  },
  mounted() {
    store.commit("showLoader", true);
    let self = this;
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;
    const listUrl = '/opportunities/resource/view/' + self.editId;
    axios
        .get(listUrl)
        .then((response) => {
          if (response.data) {
            self.data = response.data.data;
            self.currency = response.data.data.currency.title;
            self.experience = response.data.data.experience.title;
            self.user = response.data.data.user.name;
            self.companyName = response.data.data.user.companyName;
            store.commit("showLoader", false); // Loader Off
          }
        })
        .catch(function () {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {},
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
