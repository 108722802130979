<template>
  <CRow>
    <CModal
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
        :hide-footer="true"
    >
      <center>
        <img alt="Document" :src="viewImage"/>
      </center>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CModal
        size="lg"
        title="Detail"
        :show.sync="MediaDetailModal"
        :no-close-on-backdrop="true"
        color="primary"
        :hide-footer="true"
    >
      <table class="table table-bordered">
        <tr>
          <th>Type</th>
          <td>{{ detail.type }}</td>
        </tr>
        <tr>
          <th>Date</th>
          <td>{{ detail.date }}</td>
        </tr>
        <tr>
          <th>URL</th>
          <td>{{ detail.url }}</td>
        </tr>
      </table>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CCol col="12">
      <div v-if="totalMedia(mediaLists) > 0">
        <div id="people">
          <div class="row row-sm">
            <div
                v-for="(media) in mediaLists"
                :key="media.id"
                class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-2"
            >
              <div class="card card-file media">
                <div class="card-file-thumb">
                  <i class="fa fa-file-word-o"></i>
                </div>
                <div class="card-body text-center">
                  <div v-if="media.mediaName === 'Image'">
                    <img
                        :src="media.url"
                        alt="Media"
                        @click="open(media.url, media.type)"
                        style="cursor: pointer"
                        height="98"
                        class="mx-auto d-block"
                    />
                    <template>
                      <CDropdown toggler-text="Actions">
                        <CDropdownItem :href="media.url">Download</CDropdownItem>
                        <CDropdownItem @click="openDetail(media)">Detail</CDropdownItem>
                      </CDropdown>
                    </template>
                  </div>
                  <div v-else-if="media.mediaName !== 'Image'">
                    <img
                        :src="media.defaultImage"
                        alt="Default Image"
                        height="98"
                        class="mx-auto d-block"
                    />
                    <template>
                      <CDropdown toggler-text="Actions">
                        <CDropdownItem :href="media.url">Download</CDropdownItem>
                        <CDropdownItem @click="openDetail(media)">Detail</CDropdownItem>
                      </CDropdown>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button @click="btnHandleScroll()" class="col-12 btn btn-primary" v-if="btn_dis>0">
                {{ $lang.notification.load_more }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        {{ $lang.opportunity_media.label.no_content }}
      </div>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {opportunity} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "OpportunityMedia",
  mixins: [Mixin],
  data() {
    return {
      detail: "",
      MediaDetailModal: false,
      viewImage: "",
      Type: "",
      largeModal: false,
      ViewResponse: "",
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      items: [],
      reasonOptions: [],
      mediaLists: [],
      activePage: 1,
      listUrl: "",
      module: opportunity,
      data: [],
      count: 0,
      page: 1,
      to: 0,
      btn_dis: 1,
    };
  },
  created() {
    let self = this;
    self.listUrl = "/opportunities/resource/media/";
  },
  mounted() {
    store.commit("showLoader", false); // Loader Off
    let self = this;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;
    self.listUrl = this.listUrl + self.editId + "?page=" + self.page;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    axios.get(self.listUrl).then(function (response) {
      if (response) {
        self.count = response.data.total;
        self.to = response.data.to;
        self.mediaLists = response.data.data;
        if (response.data.lastPage === self.page) {
          self.btn_dis = 0;
        }
      } else {
        self.data = [];
      }
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    openDetail(media) {
      let self = this;
      self.detail = media;
      self.MediaDetailModal = true;
    },
    btnHandleScroll(event) {
      let self = this;
      self.page = self.page + 1;
      if (self.to < self.count) {
        self.listUrl = '/opportunities/resource/media/' + self.editId + "?page=" + self.page;
        axios.get(self.listUrl, self.model)
            .then((response) => {
              self.mediaLists = self.mediaLists.concat(response.data.data);
              self.count = response.data.total;
              self.to = response.data.to;
              if (response.data.lastPage === self.page) {
                self.btn_dis = 0;
              }
            });
      }
    },
    open(url, type) {
      let self = this;
      self.viewImage = url;
      self.Type = type;
      self.largeModal = true;
    },
    totalMedia(items) {
      return items.length;
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
<style>
.media img {
  width: 100%;
}
</style>
